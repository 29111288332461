import React, {useEffect, useMemo, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {Container, Grid} from '@mui/material';
import {FormikTextField} from '@src/components/FormikFields';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import {frequentlyAskedQuestionsSchema} from '@src/modules/frequentlyAskedQuestions/schema';
import {useAddFrequentlyAskedQuestionsMutation, useGetFrequentlyAskedQuestionsQuery} from '@src/modules/frequentlyAskedQuestions/frequentlyAskedQuestions.api';
import PageHeader from '@src/components/PageHeader';
import {MAX_QUESTION_LENGTH, MAX_ANSWER_LENGTH} from '@src/modules/frequentlyAskedQuestions/utils';
import {CategoryTagsField} from '@src/components/CategoryTagsField';

export default function FrequentlyAskedQuestionsCreate(){
    const [tags, setTags] = useState([]);

    const {t} = useTranslation();
    const [createFaq, {isLoading, isSuccess, error}] = useAddFrequentlyAskedQuestionsMutation();
    const {data: faqData} = useGetFrequentlyAskedQuestionsQuery();

    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const listTags = faqData?.map(value => value.tags);

    const tagsArrayMerge = useMemo(() => {
        if (listTags){
            return listTags.flat();
        }
    }, [listTags]);

    const tagsNames = {};

    tagsArrayMerge?.forEach(item => {
        const lowerItem = item?.toLowerCase();
        const FormattedName = lowerItem?.charAt(0)?.toUpperCase() + lowerItem?.slice(1);
        tagsNames[FormattedName] = true;
    });

    const tagsOptions = Object.keys(tagsNames);

    const formik = useFormik({
        validationSchema: frequentlyAskedQuestionsSchema(t),
        initialValues: {
            question: '',
            answer: '',
            tags: [],
        },
        onSubmit: async (form) => {
            await createFaq({body: form}).unwrap();
            navigate(routes.FAQ);
        },
    });

    const formSubmit = (e) => {
        e.preventDefault();
        formik.handleSubmit();
    };

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar(t('FAQ.CREATE_SUCCESS'), {variant: 'success'});
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t('FAQ.CREATE_ERROR'), {variant: 'error'});
        }
    }, [error]);

    return (
        <Container maxWidth='xl'>
            <PageHeader title={'FAQ.CREATED'} textTooltip={t('FAQ.BACK')} toBack={() => navigate(routes.FAQ)} />
            <form onSubmit={formSubmit} autoComplete='off'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormikTextField
                            fullWidth
                            name='question'
                            label={t('FAQ.QUESTION')}
                            formik={formik}
                            maxLength={MAX_QUESTION_LENGTH}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CategoryTagsField
                            name='tags'
                            label={t('FAQ.CATEGORY_TAGS')}
                            formik={formik}
                            tags={tags}
                            setTags={setTags}
                            tagsOptions={tagsOptions}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextField
                            error={formik.values.answer.length === MAX_ANSWER_LENGTH}
                            helperText={formik.values.answer.length === MAX_ANSWER_LENGTH ? t('FAQ.MAX_LENGTH') : ''}
                            fullWidth
                            name='answer'
                            label={t('FAQ.RESPONSE')}
                            formik={formik}
                            multiline
                            rows={6}
                            maxLength={MAX_ANSWER_LENGTH}
                        />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <LoadingButton
                            type='submit'
                            loading={isLoading}
                            variant='contained'
                            fullWidth
                        >
                            {t('FAQ.SAVE')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}
