import React, {useEffect, useState} from 'react';
import {EditorContent, useEditor} from '@tiptap/react';
import {Color} from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import TextAlign from '@tiptap/extension-text-align';
import TextUnderline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';

import {MenuBar} from './MenuBar';
import LinkPopover from './LinkPopover';
import './tip-tap.scss';
import styles from './text-editor.module.scss';

export default function TextEditor({initialValue, onChange, onBlur = () => {}}) {
    const [url, setUrl] = useState('');
    const [showMenuAdd, setShowMenuAdd] = useState(false);
    
    const editor = useEditor({
        extensions: [
            Color,
            TextStyle,
            TextAlign,
            TextUnderline,
            StarterKit,
            Link.configure({
                openOnClick: false,
                autolink: false,
            }),
        ],
        content: initialValue,
        onUpdate: ({editor}) => {
            if (typeof onChange === 'function') {
                onChange(editor.getHTML());
            }
        },
        onBlur: onBlur,
    }, [initialValue]);

    useEffect(() => {
        setUrl(editor?.getAttributes('link').href);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor?.getAttributes('link').href]);

    return (
        <div className={styles.container}>
            <div className={styles.menuBarContainer}>
                <MenuBar editor={editor} setShowMenuAdd={setShowMenuAdd} url={url} />
            </div>

            <LinkPopover
                setUrl={setUrl}
                url={url}
                editor={editor}
                showMenuAdd={showMenuAdd}
                setShowMenuAdd={setShowMenuAdd}
            />

            <div className={styles.editorContainer}>
                <EditorContent editor={editor} />
            </div>
        </div>
    );
}
