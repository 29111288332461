import React from 'react';
import {useDebounce} from '@src/hooks/useDebounce';
import {Formik, useFormik} from 'formik';
import {useEffect, useState} from 'react';
import {TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';

import styles from './search-form.module.scss';

export function SearchForm({setSearch}) {
    const {t} = useTranslation();

    const [inputValue, setInputValue] = useState('');
    const debouncedSearchTerm = useDebounce(inputValue);

    const formik = useFormik({
        initialValues: {
            search: '',
        },
    });

    useEffect(() => {
        setSearch(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    return (
        <div className={styles.searchContainer}>
            <Formik>
                <TextField
                    fullWidth   
                    id='search'
                    name='search'
                    label={t('ACCOUNTS_PAGE.SEARCH_BY_ACCOUNT_NAME')}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.search && Boolean(formik.errors.search)}
                    helperText={formik.touched.search && formik.errors.search}
                  />
            </Formik>
        </div>
    );
}
