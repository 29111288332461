import * as yup from 'yup';

import {validateCNPJ} from './utils';

export const createAccountSchema = (t) =>
    yup.object().shape({
        name: yup.string().trim().required(t('REQUIRED_FIELD')),
        mu: yup.number().required(t('REQUIRED_FIELD')),
        subdomain: yup
            .string()
            .trim()
            .required(t('REQUIRED_FIELD'))
            .matches(/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$/, t('ACCOUNTS_PAGE.VALIDATION.SUBDOMAIN_PATTERN')),
        isDemo: yup.boolean().optional().default(false),
        cnpj: yup.string().trim().test('invalid-cnpj',  t('ACCOUNTS_PAGE.INVALID_CNPJ'), (value) => value ? validateCNPJ(value) : true),
        company_name: yup.string().trim(),
        address: yup.object().shape({
            postal_code: yup.string().trim().min(8, t('ACCOUNTS_PAGE.THE_CEP_MUST_BE_8_DIGITS')),
            street: yup.string(),
            number: yup.string(),
            neighborhood: yup.string(),
            city: yup.string(),
            state: yup.string(),
            additional_info: yup.string().optional(),
        }),
        financial_responsible: yup.object({
            name: yup.string(),
            email: yup.string().trim().email(t('INVALID_EMAIL')),
            phone: yup.string(),
        }),
        value_per_user: yup.number().nullable(),
        min_users_number: yup.number().nullable(),
        close_invoice: yup.number().nullable(),
        payment_day: yup.number().nullable(),
        contract_initial_day: yup.date().nullable(),
        contract_final_day: yup.date().nullable(),
        responsible: yup.object({
            name: yup.string().trim().required(t('REQUIRED_FIELD')),
        }),
        re: yup
            .string()
            .trim()
            .email(t('INVALID_EMAIL'))
            .required(t('REQUIRED_FIELD')),
        rp: yup.string().trim().min(8, t('ACCOUNTS_PAGE.VALIDATION.PASSWORD_MIN_LENGTH')).required(t('REQUIRED_FIELD')),
        modules: yup.object({
            climateCheck: yup.boolean(),
        },
        ),
        timezone: yup.object().shape({
            label: yup.string(),
            value: yup.string(),
        }).nullable().required(t('REQUIRED_FIELD')),
        type: yup.string().nullable().required(t('REQUIRED_FIELD')),
    });

export const createDemoAccountSchema = (t) =>
    yup.object({
        name: yup.string().trim().required(t('REQUIRED_FIELD')),
        subdomain: yup
            .string()
            .trim()
            .required(t('REQUIRED_FIELD'))
            .matches(/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$/, t('DEMO_ACCOUNTS_PAGE.VALIDATION.SUBDOMAIN_PATTERN')),
        responsibleName: yup.string().trim().required(t('REQUIRED_FIELD')),
        re: yup
            .string()
            .trim()
            .email(t('INVALID_EMAIL'))
            .required(t('REQUIRED_FIELD')),
        expiryDate: yup.date(),
    });

export const editTrainingAccountSchema = (t) =>
    yup.object({
        name: yup.string().trim().required(t('REQUIRED_FIELD')),
        mu: yup.number().required(t('REQUIRED_FIELD')),
        subdomain: yup
            .string()
            .trim()
            .required(t('REQUIRED_FIELD'))
            .matches(/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?$/, t('DEMO_ACCOUNTS_PAGE.VALIDATION.SUBDOMAIN_PATTERN')),
        responsible: yup.object({
            name: yup.string().trim().required(t('REQUIRED_FIELD')),
        }),
        re: yup
            .string()
            .trim()
            .email(t('INVALID_EMAIL'))
            .required(t('REQUIRED_FIELD')),
        expiryDate: yup.date(),
        modules: yup.object({
            climateCheck: yup.boolean(),
        }),
        disabled: yup.boolean(),
    });

export const editAccountTabOnAccountSchema = (t, minUsers = 0) =>
    yup.object({
        name: yup.string().trim().required(t('REQUIRED_FIELD')),
        subdomain: yup.string().trim().required(t('REQUIRED_FIELD')),
        mu: yup.number().min(
            minUsers,
            t('ACCOUNTS_PAGE.MAX_SIZE_ERROR'),
        ).nullable(),
        type: yup.string().required(t('REQUIRED_FIELD')),
        timezone: yup.object().shape({
            label: yup.string(),
            value: yup.string(),
        }).nullable().required(t('REQUIRED_FIELD')),
        responsible: yup.object({
            name: yup.string().trim().required(t('REQUIRED_FIELD')),
        }),
        re: yup
            .string()
            .trim()
            .email(t('INVALID_EMAIL'))
            .required(t('REQUIRED_FIELD')),
    });

export const editClientTabOnAccountSchema = (t) =>
    yup.object({
        cnpj: yup.string().trim().test('invalid-cnpj',  t('ACCOUNTS_PAGE.INVALID_CNPJ'),  (value) => value ? validateCNPJ(value) : true),
        company_name: yup.string().trim().nullable().optional(),
        address: yup.object({
            postal_code: yup.string().trim().min(8, t('ACCOUNTS_PAGE.THE_CEP_MUST_BE_8_DIGITS')).nullable().optional(),
            street: yup.string().nullable().optional(),
            number: yup.string().nullable().optional(),
            neighborhood: yup.string().nullable().optional(),
            city: yup.string().nullable().optional(),
            state: yup.string().nullable().optional(),
            additional_info: yup.string().nullable().optional(),
        }),
    });

export const editFinanceTabOnAccountSchema = (t, maxUsers) =>
    yup.object({
        value_per_user: yup.number().nullable(),
        min_users_number: yup.number().max(
            maxUsers,
            t('ACCOUNTS_PAGE.MIN_SIZE_ERROR'),
        ).nullable(),
        close_invoice: yup.number().nullable(),
        payment_day: yup.number().nullable(),
        contract_initial_day: yup.date().nullable(),
        contract_final_day: yup.date().nullable(),
    });

export const editModulesTabOnAccountSchema = () =>
    yup.object({
        modules: yup.object({
            climateCheck: yup.boolean(),
        }),
    });

export const changePasswordAccountSchema = (t) =>
    yup.object({
        password: yup.string().trim().required(t('REQUIRED_FIELD')),
    });

export const accountStatus = {
    READY: 'ready',
    PREPARING: 'preparing',
};

export const accountPayingType = {
    FREE: 'Free',
    PAID: 'Paid',
    EXPERIMENTAL: 'Experimental',
};

export const boletosStatus = {
    PAID: 'Paid',
    EXPIRED: 'Expired',
    PENDING: 'Pending',
};

export const translateAccountStatus = {
    [accountStatus.READY]: 'ACCOUNTS_PAGE.READY',
    [accountStatus.PREPARING]: 'ACCOUNTS_PAGE.PREPARING',
};

export const translateAccountPayingType = {
    [accountPayingType.FREE]: 'ACCOUNTS_PAGE.FREE',
    [accountPayingType.PAID]: 'ACCOUNTS_PAGE.PAY',
    [accountPayingType.EXPERIMENTAL]: 'ACCOUNTS_PAGE.EXPERIMENTAL',
};

export const translateBoletosStatus = {
    [boletosStatus.PAID]: 'ACCOUNTS_PAGE.BOLETOS.PAID',
    [boletosStatus.EXPIRED]: 'ACCOUNTS_PAGE.BOLETOS.EXPIRED',
    [boletosStatus.PENDING]: 'ACCOUNTS_PAGE.BOLETOS.PENDING',
};

export const accountTypes = {
    commercials: 'commercials',
    trainings: 'trainings',
};
