import React, {createContext, useMemo, useState} from 'react';
import {Box, Chip, IconButton, Tooltip} from '@mui/material';
import Table from '@src/components/Table';
import {useTranslation} from 'react-i18next';
import * as moment from 'moment';
import {CopyAllOutlined} from '@mui/icons-material';
import {useSnackbar} from 'notistack';

import {toCurrency} from '../../utils';
import {boletosStatus, translateBoletosStatus} from '../../schema';

import styles from './boletosTable.module.scss';

const BoletosTableContext = createContext({});

function BoletosTable({data, isLoading, setPage, pageSize, total, page, isError}) {
    const {t} = useTranslation();
    const [id, setId] = useState(null);
    const columns = useMemo(() => [
        {accessor: 'value', Header: t('ACCOUNTS_PAGE.BOLETOS.VALUE')},
        {accessor: 'status', Header: t('ACCOUNTS_PAGE.BOLETOS.STATUS'), Cell: ({cell: {value}}) => <BoletoStatus status={value} />},
        {accessor: 'expirationDate', Header: t('ACCOUNTS_PAGE.BOLETOS.EXPIRATION_DATE')},
        {accessor: 'createdAt', Header: t('ACCOUNTS_PAGE.CREATED_AT')},
    ], [t]);
    
    const rows = useMemo(() => {
        if (!data) {
            return [];
        }

        return [...data]
            .sort((a, b) => moment(b.created_at) - moment(a.created_at))
            .map((row) => ({
                id: row.id,
                value: toCurrency(row.value),
                status: row.status,
                expirationDate: moment(row.payment.boleto.expirationDate).format('DD/MM/YYYY'),
                createdAt: moment(row.created_at).format('DD/MM/YYYY HH:mm:ss'),
                url: row.payment.boleto.url,
            }));
    }, [data]);

    const paginateArray = (array, pageSize, pageNumber) => {
        const startIndex = (pageNumber) * pageSize;
        const endIndex = startIndex + pageSize;
        return array.slice(startIndex, endIndex);
    };

    return (
        <BoletosTableContext.Provider value={{rows, id, setId}}>
            <Table
                columns={columns}
                isError={isError}
                data={paginateArray(rows, pageSize, page)}
                notFoundText={t('ACCOUNTS_PAGE.NO_BOLETOS_FOUND')}
                Actions={Actions}
                actionsClassName={styles.actions}
                loading={isLoading}
                setPage={setPage}
                pageSize={pageSize}
                total={total}
                pageActual={page}
            />
        </BoletosTableContext.Provider>
    );
}
const Actions = ({data}) => {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const handleCopy = () => {
        navigator.clipboard.writeText(data.url);
        enqueueSnackbar(t('COPIED'), {variant: 'success'});
    };

    return (
        <div className={styles.actions}>
            <Box ml={2}>
                <Tooltip title={t('COPY')}>
                    <IconButton disabled={data?.status !== boletosStatus.PENDING} size='small' onClick={handleCopy}>
                        <CopyAllOutlined />
                    </IconButton>
                </Tooltip>
            </Box>
        </div>
    );
    
};
const BoletoStatus = ({status}) => {
    const {t} = useTranslation(); 
    const statusStyle = {
        [boletosStatus.PENDING]: styles.chipPending,
        [boletosStatus.EXPIRED]: styles.chipExpired,
        [boletosStatus.PAID]: styles.chipPaid,
    };

    return <Chip
        label={t(translateBoletosStatus[status])} 
        color={'default'}
        className={statusStyle[status]}
        size='small'
        variant='outlined'  />;
};
export default BoletosTable;
