import React, {useState} from 'react';
import {LoadingButton, TabContext, TabList, TabPanel} from '@mui/lab';
import {
    Container,
    Tab,
} from '@mui/material';
import {useCreateMutation, useGetContractUrlMutation} from '@src/modules/accounts/accounts.api';
import {accountTypes, createAccountSchema} from '@src/modules/accounts/schema';
import {Formik, useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {routes} from '@src/utils/routes';
import {createAccountInitialValues} from '@src/modules/accounts/utils';
import moment from 'moment';
import {uploadFile} from '@src/utils/file-utils';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import styles from './AccountCreate.module.scss';
import {AccountCreateTab} from './CreateTabs/AccountCreateTab';
import {ClientCreateTab} from './CreateTabs/ClientCreateTab';
import {FinanceCreateTab} from './CreateTabs/FinanceCreateTab';
import {ModulesCreateTab} from './CreateTabs/ModulesCreateTab';

export default function AccountCreate() {
    const [tabIndex, setTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState();
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const [create] = useCreateMutation();
    const [getUploadContratUrl] = useGetContractUrlMutation();
    const formik = useFormik({
        validationSchema: createAccountSchema(t),
        initialValues: createAccountInitialValues,
        enableReinitialize: true,
        onSubmit: async (form) => {
            try {
                setIsLoading(true);
                const result = await create({type: accountTypes.commercials, data: {
                    ...form,
                    name: form.name,
                    subdomain: form.subdomain,
                    max_users: form.mu || null,
                    cnpj: form.cnpj || null,
                    company_name: form.company_name || null,
                    address: {
                        postal_code: form?.address?.postal_code || null,
                        street: form?.address?.street || null,
                        number: form?.address?.number || null,
                        neighborhood: form?.address?.neighborhood || null,
                        city: form?.address?.city || null,
                        state: form?.address?.state || null,
                        additional_info: form.address?.additional_info || null,
                    },
                    financial_responsible: {
                        name: form.financial_responsible?.name || null,
                        email: form.financial_responsible?.email || null, 
                        phone: form.financial_responsible.phone || null,
                    },
                    value_per_user: form?.value_per_user || null,
                    min_users_number: form?.min_users_number || null,
                    close_invoice: form?.close_invoice || null,
                    payment_day: form?.payment_day || null,
                    contract_initial_day: form.contract_initial_day ? moment(form.contract_initial_day).toISOString() : null,
                    contract_final_day: form.contract_final_day ? moment(form.contract_final_day).toISOString() : null,
                    responsible: {
                        name: form.responsible?.name || null,
                    },
                    modules: {
                        climateCheck: form.modules?.climateCheck || false,
                    },
                    timezone: form.timezone.value,
                    type: form?.type,
                }}).unwrap();
                if (file){
                    const params = {
                        ContentType: file.type,
                        ContentLength: file.size,
                    };
                    const urlData = await getUploadContratUrl({accountId: result.id, params}).unwrap();
                    await uploadFile(urlData.url, urlData.fields, file, {contentDisposition: false});
                }
                enqueueSnackbar(t('ACCOUNTS_PAGE.CREATE_SUCCESS'), {variant: 'success'});
                navigate(routes.ACCOUNTS);
            } catch (error) {
                if (error?.status === 404) {
                    enqueueSnackbar(t('ACCOUNTS_PAGE.NO_TEMPLATE_FOUND'), {variant: 'error'});
                } else {
                    enqueueSnackbar(t('ACCOUNTS_PAGE.CREATE_ERROR'), {variant: 'error'});
                }
            } finally {
                setIsLoading(false);
            }

        },
    });
    
    const hasTouched = Object.keys(formik.touched)?.length > 0;
    const hasError = hasTouched && Object.keys(formik.errors)?.length > 0;

    const formSubmit = (e) => {
        e.preventDefault();
        formik.handleSubmit();
    };

    const handleChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    return (
        <Container maxWidth='xl'>
            <h1>{t('ACCOUNTS_PAGE.CREATE_TITLE')}</h1>
            <Formik
                initialValues={formik?.initialValues}
                onSubmit={formSubmit}
            >
                <form onSubmit={formSubmit} autoComplete='off'>
                    <TabContext value={tabIndex}>
                        <div className={styles.tabs}>
                            <TabList onChange={handleChange}>
                                <Tab
                                    label={t('ACCOUNTS_PAGE.TABS.ACCOUNT')}
                                    value={0}
                                    iconPosition='end' 
                                    icon={hasError && <WarningAmberIcon color='error' />} 
                                    />
                               
                                <Tab label={t('ACCOUNTS_PAGE.TABS.CLIENT')} value={1} />
                                <Tab label={t('ACCOUNTS_PAGE.TABS.FINANCE')} value={2} />
                                <Tab label={t('ACCOUNTS_PAGE.TABS.MODULES')} value={3} />
                            </TabList>
                        </div>
                        <TabPanel value={0} children={<AccountCreateTab formik={formik} isLoading={isLoading} />} />
                        <TabPanel value={1} children={<ClientCreateTab  formik={formik} isLoading={isLoading}  />}  />
                        <TabPanel value={2} children={
                            <FinanceCreateTab 
                                formik={formik}
                                file={file}
                                setFile={setFile}
                                isLoading={isLoading} />} 
                            />
                        <TabPanel value={3} children={<ModulesCreateTab formik={formik} />} />
                        <div className={styles.row}>
                            <LoadingButton
                                type='submit'
                                loading={isLoading}
                                variant='contained'
                                onClick={formSubmit}
                                fullWidth
                        >
                                {t('ACCOUNTS_PAGE.SAVE')}
                            </LoadingButton>
                        </div>
                    </TabContext>
                </form>
            </Formik>
        </Container>
    );
}
