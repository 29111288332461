import React, {useState} from 'react';
import {useListAllBoletosQuery} from '@src/modules/accounts/accounts.api';
import {useParams} from 'react-router-dom';
import BoletosTable from '@src/modules/accounts/components/BoletosTable';
import {Alert, AlertTitle} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function BoletosEditTab({formikAccount, formikFinance, formikClient}) {
    const [page, setPage] = useState(0);
    const {id} = useParams();
    const {t} = useTranslation();
    const {data: allBoletos, isLoading, isError} = useListAllBoletosQuery({id});
    const warningMessages = showAlert(t, formikAccount, formikClient, formikFinance);
    
    return (
        <div>
            {warningMessages.length > 0 && (
                <Alert severity='warning'>
                    <AlertTitle>{t('ACCOUNTS_PAGE.BOLETOS.WARNING')}</AlertTitle>
                    {t('ACCOUNTS_PAGE.BOLETOS.FILL_ALL_INFORMATION')}
                    <div>
                        {warningMessages.map((requiredField, idx) => (
                            <div key={idx}>
                                <span>- {requiredField}</span>
                            </div>),
                        )}
                    </div>
                </Alert>
            )}
            <BoletosTable
                isError={isError}
                data={allBoletos}
                isLoading={isLoading} 
                setPage={setPage}
                page={page} 
                pageSize={10} 
                total={allBoletos?.length} />
        </div>
    );
}

const showAlert = (t, formikAccount, formikClient, formikFinance) => {
    const requiredFieldsClient = [
        {field: 'company_name', translation: t('ACCOUNTS_PAGE.SOCIAL_NAME')},
        {field: 'cnpj', translation: t('ACCOUNTS_PAGE.CNPJ')},
        {field: 'address.postal_code', translation: t('ACCOUNTS_PAGE.FULL_ADDRESS')},
        {field: 'address.street', translation: t('ACCOUNTS_PAGE.FULL_ADDRESS')},
        {field: 'address.neighborhood', translation: t('ACCOUNTS_PAGE.FULL_ADDRESS')},
        {field: 'address.city', translation: t('ACCOUNTS_PAGE.FULL_ADDRESS')},
        {field: 'address.state', translation: t('ACCOUNTS_PAGE.FULL_ADDRESS')},
        {field: 'address.street', translation: t('ACCOUNTS_PAGE.FULL_ADDRESS')},
        {field: 'address.number', translation: t('ACCOUNTS_PAGE.FULL_ADDRESS')},
    ];
    const requiredFieldsFinance = [
        {field: 'value_per_user', translation: t('ACCOUNTS_PAGE.VALUE_PER_ACTIVE_USER')},
        {field: 'min_users_number', translation: t('ACCOUNTS_PAGE.MINIMUM_NUMBER_OF_ACTIVE_USERS')},
        {field: 'close_invoice', translation: t('ACCOUNTS_PAGE.INVITE_CLOSING_DATE')},
        {field: 'payment_day', translation: t('ACCOUNTS_PAGE.PAY_DAY')},
        {field: 'contract_initial_day', translation: t('ACCOUNTS_PAGE.START_DATE_OF_CONTRACT_VALIDITY')},
        {field: 'contract_final_day', translation: t('ACCOUNTS_PAGE.END_DATE_OF_THE_CONTRACT_TERM')},
    ];
      
    const findFieldValueWithChildren = (field, formik) => {
        const fieldParts = field.split('.');
        let fieldValue = formik?.values;
        fieldParts.forEach(part => {
            fieldValue = fieldValue?.[part];
        });
        return fieldValue;
    };

    const findEmptyValuesOnTab = (requiredFields, formik) => {
        const uniqueTranslations = new Set();
        
        requiredFields.map(({field, translation}) => {
            const fieldValue = findFieldValueWithChildren(field, formik);

            if (!fieldValue || fieldValue === '') {
                uniqueTranslations.add(translation);
            }
        },
        ).filter(Boolean);

        return uniqueTranslations;
    };

    if (!formikAccount?.values || !formikFinance?.values) {
        return [];
    }
    if (formikAccount?.values?.type !== 'Paid') {
        return [];
    }

    return [
        ...findEmptyValuesOnTab(requiredFieldsFinance, formikFinance),
        ...findEmptyValuesOnTab(requiredFieldsClient, formikClient),
    ];
};
