import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {BubbleMenu} from '@tiptap/react';
import {useTranslation} from 'react-i18next';
import {Button, IconButton} from '@mui/material';
import SvgIcon from '@src/components/SvgIcon';

import styles from './link-popover.module.scss';

export default function LinkPopover({
    url,
    setUrl,
    editor,
    showMenuAdd,
    setShowMenuAdd,
}) {
    return (
        <div>
            <BubbleMenu
                className={styles.container}
                tippyOptions={{duration: 150, placement: 'bottom'}}
                editor={editor}
                shouldShow={({editor, view}) => {
                    if (!view.focused) {
                        setShowMenuAdd(false);
                    }
                    
                    return view.focused && editor?.isActive('link');
                }}
            >
                {(showMenuAdd) &&
                    <AddLink
                        setUrl={setUrl}
                        url={url}
                        editor={editor}
                        setShowMenuAdd={setShowMenuAdd}
                        showMenuAdd={showMenuAdd}
                    />
                }
                {(!showMenuAdd && url) &&
                    <LinkOptions
                        url={url}
                        setShowMenuAdd={setShowMenuAdd}
                        editor={editor}
                    />
                }
            </BubbleMenu>
        </div>
    );
}

function LinkOptions({url, setShowMenuAdd, editor})  {
    const {t} = useTranslation();
    const [textCopied, setTextCopied] = useState('TRAININGS_PAGE.COPY');

    const removeLink = useCallback(() => {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
    }, [editor]);

    const handleCopyClick = useCallback(() => {
        navigator.clipboard.writeText(url);
        setTextCopied('TRAININGS_PAGE.COPIED');

        setTimeout(() => (
            setTextCopied('TRAININGS_PAGE.COPY')
        ), 3000);
    }, [url]);

    const options = useMemo(() => (
        [
            {
                iconName: 'Copy',
                onClick: handleCopyClick,
                text: t(textCopied),
                className: styles.copyTooltip,
            },
            {
                iconName: 'PencilSimple',
                onClick: () => setShowMenuAdd(true),
                text: t('TRAININGS_PAGE.EDIT'),
            },
            {
                iconName: 'LinkBreak',
                onClick: removeLink,
                text: t('TRAININGS_PAGE.REMOVE_LINK'),
            },
        ]

    ), [handleCopyClick, removeLink, setShowMenuAdd, t, textCopied]);

    return (
        <>
            <a className={styles.url} href={url} target='_blank' rel='noreferrer'>
                <IconButton type='button'>
                    <SvgIcon name='Link' className={styles.linkIcon} />
                </IconButton>
                <span className={styles.link}>{url?.replace(/^https?:\/\//, '')}</span>
            </a>
            <div className={styles.options}>
                {
                    options.map((option) => (
                        <IconButton
                            key={option.iconName}
                            type='button'
                            onClick={option.onClick}
                        >
                            <SvgIcon name={option.iconName} />
                        </IconButton>
                    ))
                }
            </div>
        </>
    );
}

function AddLink({editor, url, setUrl, showMenuAdd})  {
    const {t} = useTranslation();
    const inputRef = useRef(null);

    const saveLink = useCallback(() => {
        let fullUrl = url;

        if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
            fullUrl = 'https://' + url;
        }

        editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({href: fullUrl, target: '_blank'})
            .run();

        editor.commands.blur();
    }, [editor, url]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (url) {
            saveLink();
        }
    };

    const onChange = (e) => {
        const value = e.target.value;

        setUrl(value);
    };

    useEffect(() => {
        if (showMenuAdd && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showMenuAdd, url]);

    return (
        <form className={styles.saveContent} onSubmit={handleSubmit}>
            <input
                className={styles.input}
                placeholder={t('TRAININGS_PAGE.PLACEHOLDER_VALID_LINK')}
                onChange={onChange}
                value={url?.replace(/^https?:\/\//, '') || ''}
                ref={inputRef}
                autoFocus
            />
            <Button
                className={styles.button}
                disabled={!url}
                type='submit'
                variant='contained'
            >
                {t('TRAININGS_PAGE.TO_APPLY')}
            </Button>
        </form>
    );
}
